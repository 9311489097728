function findProp(key, defaultVal) {
  if (process.env[key]) {
    return process.env[key];
  } else if (typeof window !== 'undefined' && window[key]) {
    return window[key];
  } else {
    return defaultVal;
  }
}

export function getConfig() {

  // Valet mode configuration requires checking both process.env and window
  const vehicleTypesProp = findProp('REACT_APP_VEHICLE_TYPES', 'SEDAN:Sedan,SUV:SUV');
  const typesArr = vehicleTypesProp.split(',').map(type => type.trim());
  const types = typesArr.map(type => {
    const nameAndLabel = type.split(':');
    return {name: nameAndLabel[0], label: nameAndLabel[1]};
  });

  const vehicleColorsProp = findProp(
      'REACT_APP_VEHICLE_COLORS',
      'Black,White,Red,Silver,Gray,Space Gray,Blue,Gold,Brown,Green,Yellow,Orange'
  );
  const colorsArr = vehicleColorsProp.split(',').map(c => c.trim());

  const valetModeProp = findProp('REACT_APP_VALET_MODE', 'false');
  const valetMode = (valetModeProp === 'true');
  const pinMinNumDigitsStr = findProp('REACT_APP_PIN_MIN_NUM_DIGITS', '4');
  const pinMinNumDigits = Math.max(Math.min(Number(pinMinNumDigitsStr), 10), 1); // enforce from 1 - 10
  const valetLogoutOnIdleSecs = Number(findProp('REACT_APP_VALET_LOGOUT_ON_IDLE_SECS', '300')); // 5 minutes default
  const valetScreensaverOnIdleSecs = Number(findProp('REACT_APP_VALET_SCREENSAVER_ON_IDLE_SECS', '300')); // 5 minutes default
  const screensaverText = findProp('REACT_APP_SCREENSAVER_TEXT', '');
  const logoColor = findProp('REACT_APP_LOGO_COLOR', 'white');

  const addlVehicleId = findProp('REACT_APP_ADDL_VEHICLE_ID', '');
  const apiOrigin = findProp('REACT_APP_API_ORIGIN', 'https://localhost:3001');
  const trainingVideoUrl = findProp('REACT_APP_TRAINING_VIDEO_URL', '');
  const oneSignalAppId = findProp('REACT_APP_ONESIGNAL_APP_ID', '');
  const oneSignalSafariAppId = findProp('REACT_APP_ONESIGNAL_SAFARI_APP_ID', '');

  // Configuration that is not used in valet mode is here
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const disabledModules = (process.env.REACT_APP_DISABLED_MODULES || '').toLowerCase();
  const reservationExternalIdLabel = process.env.REACT_APP_RESERVATION_EXTERNAL_ID_LABEL || 'External ID';

  return {
    domain,
    clientId,
    audience,
    apiOrigin,
    trainingVideoUrl,
    oneSignalAppId,
    oneSignalSafariAppId,
    vehicleTypes: types,
    vehicleColors: colorsArr,
    addlVehicleId,
    valetMode,
    pinMinNumDigits,
    valetLogoutOnIdleSecs,
    valetScreensaverOnIdleSecs,
    screensaverText,
    logoColor,
    disabledModules,
    reservationExternalIdLabel,
  };
}

export const Modules = {
  ParkingQueue: 'queue',
  SurfaceSpots: 'surface',
  EVChargers: 'ev',
  Cameras: 'camera',
  Reservations: 'reservation',
  Fees: 'fee',
  Discounts: 'discount',
  UserVehiclePins: 'pin',
  QRReaders: 'qrreader',
  ParkingDisplays: 'parking-displays',
  Carousels: 'carousel',
}

export function isModuleDisabled(module) {
  return getConfig().disabledModules.includes(module);
}
