import React, { useState, createRef, useEffect } from 'react';
import cx from 'classnames';
import { Routes, Route } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import makeStyles from '@mui/styles/makeStyles';
import { getConfig } from "../config";

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { sidebarRoutes } from 'routes.js';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import MainDashboard from 'views/Dashboard/MainDashboard';
import ProfileComponent from 'views/Pages/Profile';
import Notifications from 'views/Pages/Notifications';
import About from 'views/Pages/About';

import Users from '../views/User/Users';
import AddUser from '../views/User/AddUser';
import EditUser from '../views/User/EditUser';
import EditGroup from '../views/User/EditGroup';
import AddVehicleForUser from '../views/Vehicle/AddVehicleForUser';
import EditVehicleForUser from '../views/Vehicle/EditVehicleForUser';
import AddVehicleForGroup from '../views/Vehicle/AddVehicleForGroup';
import EditVehicleForGroup from '../views/Vehicle/EditVehicleForGroup';
import AddLocation from '../views/Location/AddLocation';
import EditLocation from 'views/Location/EditLocation';
import AddParkingQueueItem from "../views/ParkingQueue/AddParkingQueueItem";
import AddCarousel from '../views/Carousel/AddCarousel';
import EditCarousel from '../views/Carousel/EditCarousel';
import CarouselQR from '../views/Carousel/CarouselQR';
import AddQRReader from '../views/QRReader/AddQRReader';
import EditQRReader from '../views/QRReader/EditQRReader';
import AddParkingDisplay from '../views/ParkingDisplay/AddParkingDisplay';
import EditParkingDisplay from '../views/ParkingDisplay/EditParkingDisplay';
import AddCamera from '../views/Camera/AddCamera';
import EditCamera from '../views/Camera/EditCamera';
import { ParkingQueue } from '../views/ParkingQueue/ParkingQueue';
import AddReservation from '../views/Reservation/AddReservation';
import EditReservation from '../views/Reservation/EditReservation';

import AddPins from '../views/UserVehiclePins/AddPins';
import EditPins from '../views/UserVehiclePins/EditPins';
import AddDiscount from '../views/Discount/AddDiscount';
import EditDiscount from '../views/Discount/EditDiscount';
import AddFeeComponent from '../views/FeeStructure/AddFee';
import EditFeeComponent from '../views/FeeStructure/EditFee';

import AddVehicleAnyUser from '../views/Vehicle/AddVehicleAnyUser';
import EditVehicle from '../views/Vehicle/EditVehicle';
import VehiclePhotos from '../views/Vehicle/VehiclePhotos';
import AddsubscriptionComponent from 'views/Forms/AddSubscription';
import EditsubscriptionComponent from 'views/Forms/EditSubscription';
import DiscountParkingSession from 'views/Forms/DiscountParkingSession.js';
import EditTicket from 'views/Forms/EditTicket.js';
import AddTicket from 'views/Forms/AddTicket.js';
import BookingHistory from 'views/Tables/BookingHistory';
import AddConfiguration from 'views/Pages/AddConfiguration';
import EditConfiguration from 'views/Pages/EditConfiguration';

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { valetMode } = getConfig();
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(valetMode);
  const [color] = useState('orange');
  const [bgColor] = useState('white');
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    });
  // ref for main panel div
  const mainPanel = createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} element={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const routes = sidebarRoutes();
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={'Stak Admin'}
        logo={require('assets/img/logo3.png')}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        valetMode={valetMode}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={'Stak Administration'}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.map}>
          <div className={classes.container}>
            <Routes>
              {getRoutes(routes)}
              <Route exact path='/' element={valetMode ? <ParkingQueue/> : <MainDashboard />} />
              <Route exact path='/admin/profile' element={<ProfileComponent />} />
              <Route exact path='/admin/notifications' element={<Notifications />} />
              <Route exact path='/admin/about' element={<About />} />
              <Route exact path='/users/add' element={<AddUser />} />
              <Route exact path='/users/edit/:id' element={<EditUser />} />
              <Route exact path='/groups/edit/:id' element={<EditGroup />} />
              <Route exact path='/users/:id/vehicles/add' element={<AddVehicleForUser />} />
              <Route exact path='/users/:id/vehicles/edit/:vehicleId' element={<EditVehicleForUser />} />
              <Route exact path='/admin/groups' element={<Users />} />
              <Route exact path='/groups/:id/vehicles/add' element={<AddVehicleForGroup />} />
              <Route exact path='/groups/:id/vehicles/edit/:vehicleId' element={<EditVehicleForGroup />} />
              <Route exact path='/location/add' element={<AddLocation />} />
              <Route exact path='/location/edit/:id' element={<EditLocation />} />
              <Route exact path='/admin/parking-queue/add/:locationId' element={<AddParkingQueueItem />} />
              <Route exact path='/carousels/add' element={<AddCarousel />} />
              <Route exact path='/carousels/edit/:id' element={<EditCarousel />} />
              <Route exact path='/carousel/:token' element={<CarouselQR />} />
              <Route exact path='/reservations/add' element={<AddReservation />} />
              <Route exact path='/reservations/edit/:id' element={<EditReservation />} />
              <Route exact path='/qr-reader/add' element={<AddQRReader />} />
              <Route exact path='/qr-reader/edit/:id' element={<EditQRReader />} />
              <Route exact path='/parking-display/add' element={<AddParkingDisplay />} />
              <Route exact path='/parking-display/edit/:id' element={<EditParkingDisplay />} />
              <Route exact path='/cameras/add' element={<AddCamera />} />
              <Route exact path='/cameras/edit/:id' element={<EditCamera />} />
              <Route exact path='/pins/add' element={<AddPins />} />
              <Route exact path='/pins/edit/:id' element={<EditPins />} />
              <Route
                exact
                path='/parking-sessions/:id/discount'
                element={<DiscountParkingSession />}
              />
              <Route exact path='/discount/add' element={<AddDiscount />} />
              <Route exact path='/discount/edit/:id' element={<EditDiscount />} />
              <Route exact path='/fee-structure/add' element={<AddFeeComponent />} />
              <Route exact path='/fee-structure/edit/:id' element={<EditFeeComponent />} />

              <Route exact path='/subscription/add' element={<AddsubscriptionComponent />} />
              <Route exact path='/subscription/edit/:id' element={<EditsubscriptionComponent />} />
              <Route exact path='/vehicles/add' element={<AddVehicleAnyUser />} />
              <Route exact path='/vehicles/edit/:id' element={<EditVehicle />} />
              <Route exact path='/vehicles/:vehicleId/photos' element={<VehiclePhotos />} />
              <Route exact path='/ticket/edit/:id' element={<EditTicket />} />
              <Route exact path='/ticket/create' element={<AddTicket />} />
              <Route
                exact
                path='/user-subscription-booking/:id/history'
                element={<BookingHistory />}
              />
              <Route exact path='/configuration/create' element={<AddConfiguration />} />
              <Route exact path='/configuration/edit/:id' element={<EditConfiguration />} />

              { /** <Route exact path='/admin' element={<OnboardWizard />} /> */ }
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
