import React, {useState, useEffect, useContext} from 'react';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';
import { getConfig, Modules, isModuleDisabled } from 'config';

import makeStyles from '@mui/styles/makeStyles';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import ParkingSessionService from '../../services/ParkingSessionService';
import ParkingQueueService from '../../services/ParkingQueueService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Select, MenuItem, FormControl, InputLabel, FormControlLabel } from '@mui/material';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import { useNavigate, useLocation } from 'react-router-dom';
import { ParkingSpotLabel } from '../SurfaceParking/ParkingSpot';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TextareaAutosize } from '@mui/base';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

import Close from '@mui/icons-material/Close';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import Badge from '@mui/material/Badge';
import GetAppIcon from '@mui/icons-material/GetApp';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const SessionAction = {
  CANCEL: 'CANCEL',
  LEAVE: 'LEAVE',
  REPARK_RETRIEVE: 'REPARK_RETRIEVE',
  RETRIEVE: 'RETRIEVE',
  RETRIEVE_CANCEL: 'RETRIEVE_CANCEL',
  REPARK_RESTOW: 'REPARK_RESTOW',
  READY_TO_LEAVE_RETRIEVE: 'READY_TO_LEAVE_RETRIEVE',
  READY_TO_LEAVE_CANCEL: 'READY_TO_LEAVE_CANCEL',
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  shiftRight: {
    paddingLeft: '25px',
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);
const modalStyles = makeStyles(modalStyle);

function ParkingSessions({ userId = null, groupId = null, refreshData, pageSize = 20 }) {
  const { hasPermission } = useContext(AuthContext);
  const api = useAPI();
  const config = getConfig();
  const addlVehicleId = config.addlVehicleId;

  const navigate = useNavigate();
  const location = useLocation();
  const searchText = location.state?.searchText;
  const classes = useStyles();
  const modalClasses = modalStyles();
  const tableRef = React.useRef();
  const showDiscounts = React.useRef();
  const [currentSessionAndPaymentDetails, setCurrentSessionAndPaymentDetails] = useState();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(false);

  // search over all sessions if search text was specified
  const [showActiveSessionsOnly, setShowActiveSessionsOnly] = useState(!searchText);

  const [smsModal, setSmsModal] = useState(false);
  const [smsMessage, setSmsMessage] = useState('');
  const [smsSessionId, setSmsSessionId] = useState(0);

  const [alertModal, setAlertModal] = useState(false);
  const [sessionAlerts, setSessionAlerts] = useState([]);

  const [showRetrieveModal, setShowRetrieveModal] = useState(false);
  const [retrieveChoice, setRetrieveChoice] = useState();
  const [cancelReason, setCancelReason] = useState();
  const [retrieveModalMultiPageConfigs, setRetrieveModalMultiPageConfigs] = useState([]);
  const [retrieveModalPageIndex, setRetrieveModalPageIndex] = useState(0);
  const [retrieveModalConfig, setRetrieveModalConfig] = useState();

  useEffect(() => {
    ParkingSessionService.init(api);
    ParkingQueueService.init(api);
    ParkingSessionService.discountList().then(list => {
      showDiscounts.current = (list.total > 0);
    })
  }, [api]);

  const refreshTable = () => {
    tableRef.current && tableRef.current.onChangePage({}, 0) && tableRef.current.onQueryChange();
  }

  /**
   * Refresh data when active sessions flag changes
   */
  useEffect(() => {
    refreshTable();
  }, [showActiveSessionsOnly, refreshData]);

  const fetchParkingSessions = (query) =>
    new Promise((resolve, reject) => {
      ParkingSessionService.list(
        query.pageSize,
        query.page + 1,
        query.search,
        query.orderBy ? query.orderBy.field : null,
        query.orderDirection,
        userId,
        groupId,
        showActiveSessionsOnly,
      )
        .then((res) => {
          resolve({
            data: transformSession(res.results),
            page: res.page - 1,
            totalCount: res.total,
          });
        })
        .catch(reject);
    });

  function convertMinutesToHHMM(minutes) {
    if (typeof minutes !== 'number' || !Number.isInteger(minutes) || minutes < 0) {
      return '';
    }
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;

    // Padding the hours and minutes with leading zeros if needed
    let paddedHours = hours.toString().padStart(2, '0');
    let paddedMinutes = mins.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}`;
  }

  function transformSession(session) {
    let transformed_session = session;
    transformed_session = transformed_session.map((ses) => {
      const allowRemoteControl =
        ParkingSessionService.isSessionPreStowing(ses)
        || ParkingSessionService.isSessionParked(ses)
        || ParkingSessionService.isSessionAvailableForReparking(ses);
      const isParked = ParkingSessionService.isSessionParked(ses);
      let sessionEndTime = 'NA', exceedsReservation = false;
      if (ses.end_time) {
        sessionEndTime = moment(ses.end_time).format('MM/DD/YYYY hh:mm A');
      } else if (ses.reservation) {
        const reservationEnd = new Date(ses.reservation.end_time);
        if (reservationEnd.getTime() < Date.now()) {
          sessionEndTime = 'Exceeds reservation';
          exceedsReservation = true;
        }
      }

      return {
        id: ses.id,
        location_id: ses.location_id,
        user_vehicle_pin: ses.user_vehicle_pin,
        vehicle_name: ses.vehicle_name,
        vehicle_id: ses.vehicle_id,
        key_tag: ses.key_tag,
        u_id: ses.user.id,
        user_id: ses.user ? ses.user.name : 'NA',
        duration: convertMinutesToHHMM(ses.duration),
        alerts: ses.alert,
        allowRemoteControl,
        isParked,
        end_time: sessionEndTime,
        exceedsReservation,
        start_time: (ses.start_time && moment(ses.start_time).format('MM/DD/YYYY hh:mm A')) || 'NA',
        status: ses.status,
        cancel_reason: ses.cancel_reason,
        parking_spot: ses.parking_spot,
        carousel: ses.carousel,
        pallet: ses.pinInfo?.pallet_number,
        plc_pin: ses.pinInfo?.plc_pin,
        parking_queue: ses.vehicle?.parking_queue,
        addl_vehicle_id: ses.vehicle?.addl_vehicle_id,
      };
    });
    return transformed_session;
  }

  useEffect(() => {
    if (retrieveModalPageIndex < retrieveModalMultiPageConfigs.length) {
      setRetrieveModalConfig(retrieveModalMultiPageConfigs[retrieveModalPageIndex]);
    } else {
      setRetrieveModalConfig(null);
    }
  }, [retrieveModalMultiPageConfigs, retrieveModalPageIndex]);

  useEffect(() => {
    if (retrieveChoice === SessionAction.RETRIEVE) {
      closeRetrieveModel();
      handleRetrieveModalAction(currentSessionAndPaymentDetails);
    }
  }, [retrieveChoice]);

  const sendSMS = () => {
    ParkingSessionService.sendSMS(smsSessionId, smsMessage)
      .catch((err) => {
        console.log('Error sending SMS', err);
      });
  };

  const handleSmsMessage = (event) => {
    setSmsMessage(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    setError('');
  };

  const queueForRetrieval = async (rowData) => {
    if (confirm(`Queue ${rowData.vehicle_name} for retrieval?`)) {
      try {
        await ParkingQueueService.queueVehicle(
          rowData.location_id,
          rowData.vehicle_id,
        );
        navigate('/admin/parking-queue');
      } catch (err) {
        alert(err);
      }
    }
  }

  const hasParkingCharge = () => {
    return currentSessionAndPaymentDetails?.paymentSummary?.chargedAmount > 0;
  }

  const retrieveParkingSession = async (sessionId, chargeFailed) => {
    const sessionAndPaymentDetails = await ParkingSessionService.getSessionDetailAndPaymentSummary(sessionId);
    const session = sessionAndPaymentDetails.session;
    const paymentSummary = sessionAndPaymentDetails.paymentSummary;
    const hasCharge = paymentSummary?.chargedAmount > 0;

    let modalConfig = [];

    const processChargeMsg = hasCharge ?
    `Process the $${paymentSummary?.chargedAmount} charge`:
    'Complete session with no charge';

    if (ParkingSessionService.isSessionPreStowing(session)) {
      modalConfig = [
        {
          default : SessionAction.CANCEL,
          title : "Car has not yet parked",
          options : {
            [SessionAction.CANCEL] : "Do you want to cancel this session?"
          }
        }
      ];
    }
    else if (ParkingSessionService.isSessionParked(session)){
      if (chargeFailed) {
        modalConfig = [
          {
            default : SessionAction.RETRIEVE_CANCEL,
            title : "Retrieve to leave - Payment Failed",
            options : {
              [SessionAction.RETRIEVE_CANCEL] : "Cancel session and waive any charge",
            }
          }
        ];
      } else {
        modalConfig = [
          {
            default : SessionAction.REPARK_RETRIEVE,
            title : "Retrieve Car",
            options : {
              [SessionAction.REPARK_RETRIEVE] : "Just get something from car",
              [SessionAction.LEAVE] : "Retrieve to leave",
            }
          },
          {
            default : SessionAction.RETRIEVE,
            title : "Retrieve to leave",
            options : {
              [SessionAction.RETRIEVE] : processChargeMsg,
              [SessionAction.RETRIEVE_CANCEL] : "Cancel session and waive any charge",
            }
          }
        ];
      }
    }
    else if (ParkingSessionService.isSessionAvailableForReparking(session)) {
      if (chargeFailed) {
        modalConfig = [
          {
            default : SessionAction.READY_TO_LEAVE_CANCEL,
            title : "Leave - Payment Failed",
            options : {
              [SessionAction.READY_TO_LEAVE_CANCEL] : "Cancel session and waive any charge",
            }
          }
        ];
      } else {
        modalConfig = [
          {
            default : SessionAction.REPARK_RESTOW,
            title : "Repark Car Or Leave",
            options : {
              [SessionAction.REPARK_RESTOW] : "Repark the car",
              [SessionAction.LEAVE] : "Leave",
            }
          },
          {
            default : SessionAction.READY_TO_LEAVE_RETRIEVE,
            title : "Leave",
            options : {
              [SessionAction.READY_TO_LEAVE_RETRIEVE] : processChargeMsg,
              [SessionAction.READY_TO_LEAVE_CANCEL] : "Cancel session and waive any charge",
            }
          }
        ];
      }
    }

    setRetrieveModalMultiPageConfigs(modalConfig);
    setRetrieveModalPageIndex(0);
    if (modalConfig?.length > 0) {
      setCurrentSessionAndPaymentDetails(sessionAndPaymentDetails);
      setRetrieveChoice(modalConfig[0].default);
      setShowRetrieveModal(true);
    }
  };

  const handleRetrieveModalAction = async (sessionAndPaymentDetails) => {
    const session = sessionAndPaymentDetails.session;
    const paymentSummary = sessionAndPaymentDetails.paymentSummary;
    const checkCamera = () => confirm('Check camera as this will cause the carousel to move. Proceed?');

    try {
      switch (retrieveChoice) {
        case SessionAction.CANCEL:
          await ParkingSessionService.cancelSession(session.id, cancelReason);
          break;
        case SessionAction.REPARK_RETRIEVE:
          checkCamera() && await ParkingSessionService.reparkRetrieveParkingSession(session.vehicle_id, session.user_id);
          break;
        case SessionAction.RETRIEVE:
          checkCamera() && await handlePaymentRes(session.id, (await ParkingSessionService.retrieveParkingSession(session.id, paymentSummary)));
          break;
        case SessionAction.RETRIEVE_CANCEL:
          checkCamera() && await ParkingSessionService.retrieveAndCancelSession(session.id, cancelReason);
          break;
        case SessionAction.REPARK_RESTOW:
          checkCamera() && await ParkingSessionService.reparkRestowParkingSession(session.vehicle_id, session.user_id);
          break;
        case SessionAction.READY_TO_LEAVE_RETRIEVE:
          await handlePaymentRes(session.id, (await ParkingSessionService.processPaymentAndUpdateToRetrievingReadyToLeave(session.id, paymentSummary)));
          break;
        case SessionAction.READY_TO_LEAVE_CANCEL:
          await ParkingSessionService.readyToLeaveAndCancelSession(session.id, cancelReason);
          break;
      }
    } catch (err) {
      alert(err);
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handlePaymentRes = async (sessionId, res) => {
    if (res) {
      let success = (res.success || res.payment?.success);
      if (!success) {
        const chargedAmountChanged = (res?.totalAmount);
        await retrieveParkingSession(sessionId, !chargedAmountChanged);
      }
    }
  }

  function closeRetrieveModel() {
    setShowRetrieveModal(false);
    setRetrieveModalMultiPageConfigs([]);
    setCurrentSessionAndPaymentDetails(null);
  }

  function showCancelReason() {
    return [SessionAction.CANCEL, SessionAction.RETRIEVE_CANCEL, SessionAction.READY_TO_LEAVE_CANCEL].includes(retrieveChoice);
  }

  let optionsObj = {
    actionsColumnIndex: 0,
    debounceInterval: 500,
    filtering: false,
    sorting: true,
    pageSize,
    pageSizeOptions: [5, 10, 20, 50],
    ...(searchText && {searchText})
  };

  const CHANGE_STATUS_OPTIONS_FOR_CAROUSELS = {
    PARKED: [
      {
        value: 'CUST_QUEUED_TO_RETRIEVE',
        label: 'CUST_QUEUED_TO_RETRIEVE (queue for retrieval)',
      },
      {
        value: 'CUST_QUEUED_TO_REPARK',
        label: 'CUST_QUEUED_TO_REPARK (queue for reparking)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (try retrieval function first)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (try cancel function first)',
      },
      {
        value: 'CANCELED_WAITING_TO_LEAVE',
        label: 'CANCELED_WAITING_TO_LEAVE (try cancel function first)'
      }
    ],
    CUST_QUEUED_TO_PARK: [
      {
        value: 'PARKING_READY_TO_ENTER',
        label: 'PARKING_READY_TO_ENTER (WARNING - MAKE SURE GATE IS OPEN FIRST)'
      },
      {
        value: 'READY_TO_STOW',
        label: 'READY_TO_STOW (WARNING - COULD CAUSE PALLET TO MOVE)'
      },
      {
        value: 'STOWING_COMPLETE',
        label: 'STOWING_COMPLETE (will trigger DONE command)',
      },
      {
        value: 'PARKED',
        label: 'PARKED (if stowing completed and mobile app not in use)'
      },
    ],
    READY_TO_STOW: [
      {
        value: 'STOWING_COMPLETE',
        label: 'STOWING_COMPLETE (will trigger DONE command)',
      },
      {
        value: 'PARKED',
        label: 'PARKED (if stowing completed and mobile app not in use)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    STOWING: [
      {
        value: 'PARKED',
        label: 'PARKED (if stowing completed and mobile app not in use)'
      },
      {
        value: 'STOWING_COMPLETE',
        label: 'STOWING_COMPLETE (if stowing completed and mobile app still in use)',
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    CUST_QUEUED_TO_RETRIEVE: [
      {
        value: 'PARKED',
        label: 'PARKED (if car was never retrieved)'
      },
      {
        value: 'RETRIEVING_READY_TO_LEAVE',
        label: 'RETRIEVING_READY_TO_LEAVE (if car has been retrieved and is still on carousel)'
      },
      {
        value: 'REPARKING_CAR_AVAILABLE',
        label: 'REPARKING_CAR_AVAILABLE (if car has been retrieved and driver wants to repark)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    CUST_QUEUED_TO_REPARK: [
      {
        value: 'PARKED',
        label: 'PARKED (if car was never retrieved)'
      },
      {
        value: 'REPARKING_CAR_AVAILABLE',
        label: 'REPARKING_CAR_AVAILABLE (if car has been retrieved)'
      },
      {
        value: 'RETRIEVING_READY_TO_LEAVE',
        label: 'RETRIEVING_READY_TO_LEAVE (if car has been retrieved and driver wants to leave)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    RETRIEVING_WAITING_FOR_CAR: [
      {
        value: 'PARKED',
        label: 'PARKED (if car was never retrieved)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    REPARKING_WAITING_FOR_CAR: [
      {
        value: 'PARKED',
        label: 'PARKED (if car was never retrieved)'
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)',
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)',
      },
    ],
    REPARKING_CAR_AVAILABLE: [
      {
        value: 'PARKED',
        label: 'PARKED (if car was reparked manually and mobile app not in use)'
      },
      {
        value: 'STOWING_COMPLETE',
        label: 'STOWING_COMPLETE (if car was reparked manually and mobile app still in use)',
      },
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)'
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)'
      },
    ],
    RETRIEVING_READY_TO_LEAVE: [
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)'
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)'
      },
    ],
    RETRIEVING_CAR_LEFT: [
      {
        value: 'RETRIEVED',
        label: 'RETRIEVED (if car has actually left)'
      },
      {
        value: 'CANCELED',
        label: 'CANCELED (if car has actually left)'
      },
    ]
  }

  const hideChangeStatus = (status) => {
    return !(status in CHANGE_STATUS_OPTIONS_FOR_CAROUSELS);
  }

  const [sessionStatusModalData, setSessionStatusModalData] = useState(null);
  const [newSessionStatus, setNewSessionStatus] = useState('');

  const openSessionStatusModal = (rowData) => {
    setSessionStatusModalData(rowData);
  }

  const closeSessionStatusModal = () => {
    setSessionStatusModalData(null);
    setNewSessionStatus('');
  }

  const changeSessionStatus = async () => {
    if (confirm(`Are you sure you want to change the status of session ${sessionStatusModalData?.user_vehicle_pin} from ${sessionStatusModalData.status} to ${newSessionStatus}?`)) {
      ParkingSessionService.changeSessionStatus(sessionStatusModalData.id, newSessionStatus).then(() => {
        tableRef.current && tableRef.current.onQueryChange();
      }).catch((err) => {
        alert(err);
      });
    }
    closeSessionStatusModal();
  }

  const getChangeStatusMenuItems = () => {
    if (!sessionStatusModalData) {
      return null;
    }
    const options = CHANGE_STATUS_OPTIONS_FOR_CAROUSELS[sessionStatusModalData.status];
    return options && options.map((option, idx) => (
      <MenuItem key={idx} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }

  const changeStatusDialog = () => {
    return <Dialog
      classes={{
        root: modalClasses.left,
        paper: modalClasses.modal,
      }}
      open={!!sessionStatusModalData}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeSessionStatusModal}
      aria-labelledby='modal-slide-title'
      aria-describedby='modal-slide-description'
    >
      <DialogTitle id='classic-modal-slide-title'>
        <span style={{display: 'block', textAlign: 'center', lineHeight: '1.2em'}}>Set Session Status ({sessionStatusModalData?.user_vehicle_pin})</span>
      </DialogTitle>
      <DialogContent id='modal-slide-description' className={modalClasses.modalBody}>
        <FormControl fullWidth variant='outlined'>
          <InputLabel>Choose a status</InputLabel>
          <Select
            label="Choose a status"
            value={newSessionStatus}
            onChange={(event) => setNewSessionStatus(event.target.value)}
          >
            {getChangeStatusMenuItems()}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={modalClasses.modalFooter + ' ' + modalClasses.modalFooterCenter}>
        <Button
          onClick={closeSessionStatusModal}
        >
          Cancel
        </Button>
        <Button
          onClick={changeSessionStatus}
          color='primary'
        >
          Set Status
        </Button>
      </DialogActions>
    </Dialog>
  }

  const actions = [
    {
      icon: () => <>
        <FormControlLabel
          label="Active sessions only?"
          labelPlacement="start"
          control={
            <Checkbox
              checked={showActiveSessionsOnly}
              onChange={() => {setShowActiveSessionsOnly(!showActiveSessionsOnly)}}
            />
          }
        />
      </>,
      position: 'toolbar',
      isFreeAction: true,
      onClick: () => {}, // prevent warning
    },
  ];

  if (hasPermission(Permissions.SEND_SMS)) {
    actions.push({
        icon: 'sms',
        iconProps: { style: { color: 'primary' }, fontSize: 'small' },
        tooltip: 'Send SMS',
        onClick: (event, rowData) => {
          setSmsModal(true);
          setSmsSessionId(rowData.id);
        },
      },
    );
  }

  if (hasPermission(Permissions.QUEUE_VEHICLE) && !isModuleDisabled(Modules.ParkingQueue)) {
    actions.push(
      (rowData) =>
        (rowData.isParked && !rowData.parking_queue && {
          icon: () => <FormatListNumberedIcon fontSize='small' />,
          tooltip: 'Queue for Retrieval',
          onClick: () => {
            queueForRetrieval(rowData);
          },
        }) || null
    );
    actions.push(
      (rowData) =>
          (rowData.isParked && rowData.parking_queue && {
            icon: () => <FormatListNumberedIcon style={{ color: 'red' }} fontSize='small' />,
            tooltip: 'Queued for Retrieval',
            onClick: () => {
              navigate('/admin/parking-queue');
            },
          }) || null
    );
  }

  if (hasPermission(Permissions.PARKING_SUPPORT)) {
    actions.push(
      (rowData) =>
          (rowData.allowRemoteControl && rowData.carousel && {
            icon: () => <GetAppIcon fontSize='small' />,
            tooltip: 'Remote Control',
            onClick: () => {
              retrieveParkingSession(rowData.id);
            },
          }) || null
    );
  }

  if (hasPermission(Permissions.APPLY_DISCOUNT)) {
    actions.push(
        (rowData) =>
            (rowData.isParked && !!showDiscounts?.current && {
              icon: () => <LocalAtmIcon />,
              tooltip: 'Apply Discount',
              onClick: () => {
                navigate(`/parking-sessions/${rowData.id}/discount`);
              },
            }) || null
    );
  }

  actions.push(
    (rowData) =>
        (rowData.alerts.length > 0 && {
            icon: () =>  <Badge badgeContent={rowData.alerts.length} color="info"> <NotificationsIcon style={{ color: 'red' }} fontSize='small' /> </Badge>,
            tooltip: 'Alerts',
            onClick: () => {
                setAlertModal(true);
                setSessionAlerts(rowData.alerts);
            },
        }) || null
  );

  if (hasPermission(Permissions.PARKING_SUPPORT)) {
    actions.push(
        (rowData) => (rowData.carousel && {
          icon: 'settings',
          tooltip: 'Change Status',
          hidden: hideChangeStatus(rowData.status),
          onClick: (event, rowData) => {
            openSessionStatusModal(rowData);
          },
        }) || null
    );
  }
  const showActionsColumn = actions.some(a => !a.isFreeAction);

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Snackbar open={isSnackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity='error'>
            {error}
          </Alert>
        </Snackbar>
        <Card>
          <MaterialTable
            title='Parking Sessions'
            tableRef={tableRef}
            columns={[
              { title: 'User', field: 'user_id' },
              { title: 'Vehicle', field: 'vehicle_name' },
              { title: 'Key Tag', field: 'key_tag' },
              {
                title: 'Location',
                field: 'spot',
                sorting: false,
                render: rowData => {
                  if (rowData.carousel) {
                    const tooltip = `Session ID ${rowData.id}, PIN ${rowData.plc_pin}`
                    return <span title={tooltip}>{rowData.carousel.nickname} / Pallet {rowData.pallet}</span>;
                  } else {
                    return <ParkingSpotLabel aSpot={rowData.parking_spot} />
                  }
                }
              },
              {
                title: 'Status',
                field: 'status',
                render: rowData => <Tooltip title={rowData.status === 'CANCELED' ? rowData.cancel_reason : ''}><Chip label={String(rowData.status).toUpperCase()} color={rowData.isParked ? 'primary' : 'secondary'}/></Tooltip>
              },
              {
                title: addlVehicleId,
                field: 'addl_vehicle_id'
              },
              {
                title: 'Parked At',
                field: 'start_time',
                type: 'datetime',
              },
              {
                title: 'Retrieved At',
                field: 'end_time',
                type: 'datetime',
                render: rowData => <span style={{...(rowData.exceedsReservation && {color: 'red'})}}>{rowData.end_time}</span>
              },
              { title: 'Duration (hh:mm)', field: 'duration' },
            ]}
            data={fetchParkingSessions}
            actions={showActionsColumn ? actions : null}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <LocalParkingIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={optionsObj}
          />

          { changeStatusDialog() }

          <Dialog
            classes={{
              root: classes.left,
              paper: classes.modal,
            }}
            open={smsModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSmsModal(false);
              setSmsSessionId(0);
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title'>
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => {
                  setSmsModal(false);
                  setSmsSessionId(0);
                }}
                size='small'
              >
                <Close />
              </IconButton>
              <span className={classes.shiftRight}>Send SMS</span>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <TextField
                id='outlined-multiline-static'
                label='Message'
                multiline
                rows={4}
                value={smsMessage}
                fullWidth
                onChange={handleSmsMessage}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setSmsModal(false);
                  setSmsMessage('');
                  setSmsSessionId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (smsMessage.length > 0) {
                    setSmsModal(false);
                    sendSMS();
                    setSmsMessage('');
                  }
                }}
                color='primary'
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={alertModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setAlertModal(false);
              setSessionAlerts([]);
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
              <div className={classes.modalTitle}>Session Alerts</div>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <MaterialTable
                columns={[
                  { title: 'Alert', field: 'alert' },
                  {
                    title: 'Timestamp',
                    field: 'created_at',
                    type: 'datetime',
                    cellStyle: { fontSize: 12 }
                  }
                ]}
                data={sessionAlerts}
                options={{
                  search: false,
                  paging: false,
                  showTitle: false,
                  toolbar: false
                }}
                components={{
                  Container: props => <Paper {...props} elevation={0}/>
                }}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setAlertModal(false);
                  setSessionAlerts([]);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {retrieveModalConfig &&
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal,
              }}
              open={showRetrieveModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={closeRetrieveModel}
              aria-labelledby='modal-slide-title'
              aria-describedby='modal-slide-description'
            >
              <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
                <div className={classes.modalTitle}>{ retrieveModalConfig?.title }</div>
              </DialogTitle>
              <DialogContent id='modal-slide-description' className={classes.modalBody}>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={ retrieveModalConfig?.default }
                  name="radio-buttons-group"
                  value={retrieveChoice}
                  onChange={ (event) => setRetrieveChoice(event?.target?.value) }
                  >
                    {
                      Object.keys(retrieveModalConfig?retrieveModalConfig.options:{}).map((key) =>
                        <FormControlLabel value={ key } key={ key }
                          control={<Radio />}
                          label={retrieveModalConfig.options[key]}
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                        />
                      )
                    }
                </RadioGroup>

                {
                  showCancelReason() &&
                  <TextareaAutosize
                    id='cancel_reason'
                    label='Reason'
                    value={cancelReason}
                    onChange={(event)=> setCancelReason(event?.target?.value)}
                    cols={40}
                    minRows={10}
                    aria-label="Cancel Reason"
                    placeholder="Cancel Reason"
                  />
                }
              </DialogContent>
              <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
                <Button
                  onClick={closeRetrieveModel}
                >
                  Close
                </Button>
                <Button
                  onClick={()=>{
                    if (retrieveChoice === SessionAction.LEAVE) {
                      if (hasParkingCharge()) {
                        setRetrieveChoice(retrieveModalMultiPageConfigs[retrieveModalPageIndex + 1].default);
                        setRetrieveModalPageIndex(retrieveModalPageIndex + 1);
                      } else {
                        setRetrieveChoice(SessionAction.RETRIEVE);
                      }
                    } else {
                      closeRetrieveModel();
                      handleRetrieveModalAction(currentSessionAndPaymentDetails);
                    }
                  }}
                  autoFocus
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ParkingSessions.propTypes = {
  userId: PropTypes.number,
  groupId: PropTypes.number,
  refreshData: PropTypes.bool,
  pageSize: PropTypes.number,
};

ParkingSessions.displayName = 'ParkingSessions';
export default ParkingSessions;
