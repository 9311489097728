import React from "react"
import PropTypes from "prop-types";
import { getConfig } from 'config';

import makeStyles from "@mui/styles/makeStyles"
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import DirectionsBikeSharpIcon from '@mui/icons-material/DirectionsBikeSharp';
import {StateNamesToAbbreviations} from "../../utils/Utils"

const config = getConfig();
const addlVehicleId = config.addlVehicleId;

const useStyles = makeStyles(() => ({
  horizAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const VehicleType = {
  Sedan: 'SEDAN',
  SUV: 'SUV',
  Motorcycle: 'MOTORCYCLE',
  Bicycle: 'BICYCLE',
  Oversize: 'OVERSIZE',
}

export const SUVIcon = () => {
  return <span style={{fontSize: 12, fontWeight: '600'}}>SUV &#160;</span>;
}

export const GetVehicleLabel = ({ vehicle, alignCenter = false }) => {
  const classes = useStyles();
  const prefix = vehicle.color ? `${vehicle.color} ` : '';
  let suffix = vehicle.state ? ` (${StateNamesToAbbreviations[vehicle.state]}` : '';
  if (vehicle.plate) {
    if (suffix === '') {
      suffix = ` (${vehicle.plate})`;
    } else {
      suffix += ` ${vehicle.plate})`;
    }
  }
  return (<div className={alignCenter ? classes.centerAlign : classes.horizAlign}>
    {prefix}{vehicle.make} {vehicle.model}{suffix}&#160;
    {vehicle.is_ev ? <BoltSharpIcon /> : ''}
    {vehicle.type === VehicleType.SUV ? <SUVIcon/> : ''}
    {vehicle.is_bicycle ? <DirectionsBikeSharpIcon/> : ''}
  </div>);
}

export const getVehicleLabelText = (vehicle) => {
  const additionalId = addlVehicleId ? vehicle.addl_vehicle_id : null;
  let plateData = '';
  if (vehicle.state) {
    if (vehicle.plate) {
      plateData = ` (${StateNamesToAbbreviations[vehicle.state]} ${vehicle.plate})`;
    } else {
      plateData = ` (${StateNamesToAbbreviations[vehicle.state]})`;
    }
  } else if (vehicle.plate) {
    plateData = ` (${vehicle.plate})`;
  }
  let label = vehicle.color ? `${vehicle.color} ${vehicle.make} ${vehicle.model}${plateData}` :
    `${vehicle.make} ${vehicle.model}${plateData}`
  if (additionalId) {
    label += ` | ${addlVehicleId} ${additionalId}`;
  }
  return label;
}

GetVehicleLabel.propTypes = {
  vehicle: PropTypes.shape({
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    color: PropTypes.string,
    plate: PropTypes.string,
    state: PropTypes.string,
    is_ev: PropTypes.any, // bool or int
    type: PropTypes.string,
    is_bicycle: PropTypes.bool
  }).isRequired,
  alignCenter: PropTypes.bool,
};
