/*eslint-disable*/
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useNavigate } from 'react-router-dom';
import CardIcon from 'components/Card/CardIcon.js';
import {Checkbox, FormControl, FormControlLabel, TextField} from '@mui/material';
import CardBody from 'components/Card/CardBody.js';
import LocationService from 'services/LocationService';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PropTypes from 'prop-types';
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import useAPI from 'utils/useAPI';
const useStyles = makeStyles(styles);

function LocationForm({ id = null }) {
  const classes = useStyles();
  const latitudeRegex = /^-?(([1-8]?[0-9](\.\d{1,6})?)|(90(\.0{1,6})?))$/;
  const longitudeRegex = /^-?((([1-9]?[0-9]|1[0-7][0-9])(\.\d{1,6})?)|(180(\.0{1,6})?))$/;
  const upTo5DigitsRegex = /^\d{0,5}$/;
  const zipCodeRegex = /^\d{5}$/;
  const positiveIntegerRegex = /^[1-9]\d*$/;
  const [LocationLabel] = React.useState(id ? 'Update Location' : 'Add Location');
  const [render, setRender] = React.useState(null);
  const [location, setLocation] = React.useState({
    name: '',
    latitude: '',
    longitude: '',
    zipcode: '',
    is_park_with_geolocation: true,
    parking_radius_ft: '',
    is_geolocation_debug: false,
    is_geolocation_high_accuracy: false,
    geolocation_filter_length: '',
    geolocation_filter_threshold_ft: '',
    is_geolocation_filter_replace_outliers_by_average: false,
    is_valet_only: false,
  });
  const [isGeoParking, setIsGeoParking] = React.useState(!!location.is_park_with_geolocation);
  React.useEffect(() => {
    setIsGeoParking(!!location.is_park_with_geolocation);
  }, [location.is_park_with_geolocation]);

  const api = useAPI();
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    name: '',
    latitude: '',
    longitude: '',
    zipcode: '',
    parking_radius_ft: '',
    is_geolocation_debug: '',
    is_geolocation_high_accuracy: '',
    geolocation_filter_length: '',
    geolocation_filter_threshold_ft: '',
  });

  const fetchLocation = async () => {
    await LocationService.getLocation(id).then((res) => {
      setLocation(res);
      setRender(true);
    });
  };

  React.useEffect(() => {
    LocationService.init(api);
    if (id) fetchLocation();
  }, [api]);

  const verifyLatitude = (value) => {
    return latitudeRegex.test(value);
  }

  const verifyLongitude = (value) => {
    return longitudeRegex.test(value);
  }

  const verifyUpTo5Digits = (value) => {
    return upTo5DigitsRegex.test(value);
  }

  const verifyZip = (value) => {
    return zipCodeRegex.test(value);
  }

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const verifyPositiveInteger = (value) => {
    return positiveIntegerRegex.test(value);
  };

  const submitForm = async () => {
    let error = false;
    let newErrors = errors;
    ['name', 'latitude', 'longitude', 'zipcode'].forEach((field) => {
      if (location[field] === '') {
        newErrors[field] = 'error';
        error = true;
      }
    });
    if (!verifyZip(location.zipcode)) {
      newErrors.zipcode = 'error';
      error = true;
    }
    if (isGeoParking && !location.parking_radius_ft) {
      newErrors.parking_radius_ft = 'error';
      error = true;
    }
    if (isGeoParking && !location.geolocation_filter_length) {
      newErrors.geolocation_filter_length = 'error';
      error = true;
    }
    if (isGeoParking && !location.geolocation_filter_threshold_ft) {
      newErrors.geolocation_filter_threshold_ft = 'error';
      error = true;
    }
    setErrors({ ...newErrors });
    if (error) return;

    if (!location.parking_radius_ft) {
      location.parking_radius_ft = null;
    }
    if (id) {
      await LocationService.updateLocation(location);
    } else {
      await LocationService.storeLocation(location);
    }
    navigate('/admin/location');
  };

  const handleCancel = async () => {
    navigate('/admin/location');
  };

  return (
    <GridContainer>
      {((id && render) || !id) && (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <LocationOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{LocationLabel}</h4>
            </CardHeader>
            <CardBody>
              <form>
                <TextField
                  error={errors.name === 'error'}
                  margin='dense'
                  id='name'
                  label='Name *'
                  type='text'
                  fullWidth
                  value={location.name}
                  onChange={(event) => {
                    if (verifyLength(event.target.value, 1)) {
                      setErrors({ ...errors, name: 'success' });
                    } else {
                      setErrors({ ...errors, name: 'error' });
                    }
                    setLocation({
                      ...location,
                      name: event.target.value,
                    });
                  }}
                />

                <TextField
                  error={errors.latitude === 'error'}
                  margin='dense'
                  id='latitude'
                  label='Latitude *'
                  type='text'
                  fullWidth
                  value={location.latitude}
                  onChange={(event) => {
                    if (verifyLatitude(event.target.value)) {
                      setErrors({ ...errors, latitude: 'success' });
                    } else {
                      setErrors({ ...errors, latitude: 'error' });
                    }
                    setLocation({
                      ...location,
                      latitude: event.target.value,
                    });
                  }}
                  helperText={errors.latitude === 'error' ? "Latitude must be between -90 and +90 with up to 6 decimal places" : null}
                />

                <TextField
                  error={errors.longitude === 'error'}
                  margin='dense'
                  id='longitude'
                  label='Longitude *'
                  type='text'
                  fullWidth
                  value={location.longitude}
                  onChange={(event) => {
                    if (verifyLongitude(event.target.value)) {
                      setErrors({ ...errors, longitude: 'success' });
                    } else {
                      setErrors({ ...errors, longitude: 'error' });
                    }
                    setLocation({
                      ...location,
                      longitude: event.target.value,
                    });
                  }}
                  helperText={errors.longitude === 'error' ? "Longitude must be between -180 and +180 with up to 6 decimal places" : null}
                />

                <TextField
                  error={errors.zipcode === 'error'}
                  margin='dense'
                  id='zip'
                  label='ZIP Code *'
                  type='number'
                  inputProps={{
                    maxLength: 5
                  }}
                  fullWidth
                  value={location.zipcode}
                  onChange={(event) => {
                    if (verifyUpTo5Digits(event.target.value)) {
                      setErrors({ ...errors, zipcode: 'success' });
                    } else {
                      setErrors({ ...errors, zipcode: 'error' });
                    }
                    setLocation({
                      ...location,
                      zipcode: event.target.value,
                    });
                  }}
                  helperText={errors.zipcode === 'error' ? "Zip codes must be 5 digits" : null}
                />

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!location.is_valet_only}
                        onChange={(event) => {
                          setLocation({
                            ...location,
                            is_valet_only: !!event.target.checked,
                          });
                        }}
                      />
                    }
                    label='Valet only?'
                  />
                </FormControl>

                <div>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!location.is_park_with_geolocation}
                          onChange={(event) => {
                            if (!event.target.checked && location.parking_radius_ft === '') {
                              setErrors({ ...errors, parking_radius_ft: 'success' });
                            }
                            setLocation({
                              ...location,
                              is_park_with_geolocation: !!event.target.checked,
                            });
                          }}
                        />
                      }
                      label='Allow geolocation parking'
                    />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!location.is_geolocation_high_accuracy}
                          onChange={(event) => {
                            if (!event.target.checked && location.is_geolocation_high_accuracy === '') {
                              setErrors({ ...errors, is_geolocation_high_accuracy: 'success' });
                            }
                            setLocation({
                              ...location,
                              is_geolocation_high_accuracy: !!event.target.checked,
                            });
                          }}
                        />
                      }
                      label='High accuracy geolocation'
                    />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!location.is_geolocation_debug}
                          onChange={(event) => {
                            setLocation({
                              ...location,
                              is_geolocation_debug: !!event.target.checked,
                            });
                          }}
                        />
                      }
                      label='Debug geolocation'
                    />
                  </FormControl>
                </div>

                <GridContainer spacing={2}>
                  <GridItem>
                    <TextField
                      error={errors.parking_radius_ft === 'error'}
                      margin='dense'
                      id='parking_radius_ft'
                      label='Parking radius (ft.)'
                      type='number'
                      onKeyDown={(event) => {
                        if (['.', '-', 'e', 'E'].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.slice(0, 4);
                      }}
                      value={location.parking_radius_ft}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (isGeoParking) {
                          // Geolocation parking is selected
                          if (verifyPositiveInteger(value)) {
                            setErrors({ ...errors, parking_radius_ft: 'success' });
                          } else {
                            setErrors({ ...errors, parking_radius_ft: 'error' });
                          }
                        } else {
                          // Geolocation parking is not selected
                          if (value === '' || verifyPositiveInteger(value)) {
                            setErrors({ ...errors, parking_radius_ft: 'success' });
                          } else {
                            setErrors({ ...errors, parking_radius_ft: 'error' });
                          }
                        }
                        setLocation({
                          ...location,
                          parking_radius_ft: value,
                        });
                      }}
                      helperText={
                        errors.parking_radius_ft === 'error' ?
                          'Parking radius is required for geolocation parking'
                          : null
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <TextField
                      error={errors.geolocation_filter_length === 'error'}
                      margin='dense'
                      id='geolocation_filter_length'
                      label='Filter length'
                      type='number'
                      onKeyDown={(event) => {
                        if (['.', '-', 'e', 'E'].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.slice(0, 3);
                      }}
                      value={location.geolocation_filter_length}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (isGeoParking) {
                          // Geolocation parking is selected
                          if (verifyPositiveInteger(value)) {
                            setErrors({ ...errors, geolocation_filter_length: 'success' });
                          } else {
                            setErrors({ ...errors, geolocation_filter_length: 'error' });
                          }
                        } else {
                          // Geolocation parking is not selected
                          if (value === '' || verifyPositiveInteger(value)) {
                            setErrors({ ...errors, geolocation_filter_length: 'success' });
                          } else {
                            setErrors({ ...errors, geolocation_filter_length: 'error' });
                          }
                        }
                        setLocation({
                          ...location,
                          geolocation_filter_length: value,
                        });
                      }}
                      helperText={
                        errors.geolocation_filter_length === 'error' ?
                          'Filter length is required for geolocation parking'
                          : null
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <TextField
                      error={errors.geolocation_filter_threshold_ft === 'error'}
                      margin='dense'
                      id='geolocation_filter_threshold_ft'
                      label='Filter threshold (ft.)'
                      type='number'
                      onKeyDown={(event) => {
                        if (['.', '-', 'e', 'E'].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.slice(0, 4);
                      }}
                      value={location.geolocation_filter_threshold_ft}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (isGeoParking) {
                          // Geolocation parking is selected
                          if (verifyPositiveInteger(value)) {
                            setErrors({ ...errors, geolocation_filter_threshold_ft: 'success' });
                          } else {
                            setErrors({ ...errors, geolocation_filter_threshold_ft: 'error' });
                          }
                        } else {
                          // Geolocation parking is not selected
                          if (value === '' || verifyPositiveInteger(value)) {
                            setErrors({ ...errors, geolocation_filter_threshold_ft: 'success' });
                          } else {
                            setErrors({ ...errors, geolocation_filter_threshold_ft: 'error' });
                          }
                        }
                        setLocation({
                          ...location,
                          geolocation_filter_threshold_ft: value,
                        });
                      }}
                      helperText={
                        errors.geolocation_filter_threshold_ft === 'error' ?
                          'Filter threshold is required for geolocation parking'
                          : null
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!location.is_geolocation_filter_replace_outliers_by_average}
                            onChange={(event) => {
                              if (!event.target.checked && location.is_geolocation_filter_replace_outliers_by_average === '') {
                                setErrors({ ...errors, is_geolocation_filter_replace_outliers_by_average: 'success' });
                              }
                              setLocation({
                                ...location,
                                is_geolocation_filter_replace_outliers_by_average: !!event.target.checked,
                              });
                            }}
                          />
                        }
                        label='Replace outliers by average instead of last reading'
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
                </div>

                <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                  Cancel
                </Button>
                <Button color='success' onClick={submitForm} className={classes.registerButton}>
                  {LocationLabel}
                </Button>

              </form>
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
}

LocationForm.propTypes = {
  id: PropTypes.string,
};

LocationForm.defaultProps = {
  id: null,
};

export default LocationForm;
